import { transientOptions } from '../../common/style/transientOptions'
import { theme } from '../../styles/theme'
import styled from '@emotion/styled'
import { Styles, getCssProp } from '../../utilities/getCssProp'

type StyledSvgProps = {
  $color?: string
  $shouldNotRotate?: boolean
  $isPlus: boolean
}

const StyledSvg = styled('svg', transientOptions)<StyledSvgProps>`
  cursor: pointer;
  transition: transform 0.3s ease-out;

  rotate: ${(p) => (p.$isPlus ? 45 : 0)}deg;
  :hover {
    transform: rotate(${(p) => (p.$shouldNotRotate ? 0 : 90)}deg);
  }
  :active {
    transform: rotate(${(p) => (p.$shouldNotRotate ? 0 : 90)}deg);
  }

  line {
    stroke: ${(p) => p.$color ?? theme.colors.grayscale400};
  }
`

type Props = {
  size?: number | string
  styles?: Styles
  className?: string
  isPlus?: boolean
  color?: string
  shouldNotRotate?: boolean
} & React.SVGProps<SVGSVGElement>

export function NavigationClose({
  size = 14,
  color,
  shouldNotRotate,
  styles,
  className,
  onClick,
  isPlus,
  ...rest
}: Props) {
  const cssProp = getCssProp(styles)
  return (
    <StyledSvg
      $shouldNotRotate={shouldNotRotate}
      $isPlus={!!isPlus}
      $color={color}
      className={className}
      css={cssProp}
      width={size}
      height={size}
      viewBox="0 0 14 14"
      fill="none"
      onClick={onClick}
      {...rest}
    >
      <g id="Group 4993">
        <line
          id="Line 13"
          x1="12.0673"
          y1="12.1855"
          x2="1.81422"
          y2="1.9325"
          stroke={theme.colors.grayscale400}
          strokeWidth="1.5"
          strokeLinecap="round"
        />
        <line
          id="Line 12"
          x1="12.1851"
          y1="1.93273"
          x2="1.93201"
          y2="12.1858"
          stroke={theme.colors.grayscale400}
          strokeWidth="1.5"
          strokeLinecap="round"
        />
      </g>
    </StyledSvg>
  )
}
