'use client'
import * as React from 'react'
import styled from '@emotion/styled'
import { theme } from '../../../styles/theme'
import { shopSupplementsRoute } from '../../../common/constants/routes'
import { tabletDownMq } from '../../../styles/mediaQueries'
import { HomePageCarousel } from '../molecules/HomePageCarousel/HomePageCarousel'
import { useShopPageSections } from '../../shop/pages/ShopPage'
import { HomePageFeaturedPackagesSection } from '../organisms/metrics/HomePageFeaturedPackagesSection'
import { useUserHasGoals } from '../../recommendations/hooks/useUserHasGoals'
import { useCookies } from '../../../hooks/useCookies'
import { userHasGoalsCookieName } from '../../../common/constants/cookies'
import dynamic from 'next/dynamic'
import { useAccount } from '../../auth/hooks/useAccount'
import { useFeatureFlag } from '../../auth/hooks/useFeatureFlag'
import { featureFlags } from '../../auth/FeatureFlagProvider'
import { useUserHasNonFreeMembership } from '../hooks/useUserHasNonFreeMembership'
import { HomePageBanner } from '../atoms/HomePageBanner'
import { LightGreenRoundLinkWithArrow } from '../atoms/LightGreenRoundLinkWithArrow'

const LazyExistingMemberProductSubBanner = dynamic(() =>
  import('../atoms/ExistingMemberProductSubBanner').then((x) => x.ExistingMemberProductSubBanner)
)
const LazyConferencePromotionAnnouncement = dynamic(() =>
  import('../atoms/ConferencePromotionAnnouncement').then((x) => x.ConferencePromotionAnnouncement)
)

const LazyDataSourceTilesSection = dynamic(() =>
  import('../organisms/HomePageDataTiles/HomePageDataTiles').then((x) => x.HomePageDataTiles)
)

const Container = styled.div`
  width: 100%;
  background-color: ${theme.colors.grayscale000};
`

const Section = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${tabletDownMq} {
    padding: 0 16px;
  }
`

const ContentBox = styled.div`
  width: 100%;
  margin-top: 32px;
  max-width: ${theme.spacing.standardPageMaxContentWidth};
  padding: 24px 0;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${tabletDownMq} {
    margin-top: 16px;
    padding: 8px 0;
  }
`

const SectionHeaderContainer = styled.div`
  text-align: center;

  ${tabletDownMq} {
    padding: 0 16px;
  }
`

const SectionHeaderText = styled.div`
  color: ${theme.colors.grayscale900};
  font-family: ${theme.fonts.ogg};
  font-size: 48px;
  font-weight: 300;
  line-height: 56px;
  letter-spacing: -0.24px;
`

const SectionSubheaderText = styled.div`
  margin-top: 16px;
  color: ${theme.colors.grayscale700};
  text-align: center;
  font-family: ${theme.fonts.realizeMe};
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`

const CarouselContainer = styled.div`
  margin: 16px 16px 0;
  width: 100%;
  border-radius: 12px;
  background: ${theme.colors.grayscalewhite};
`

const BottomSectionContentBox = styled(ContentBox)`
  padding: 24px 0;
`

const ContentBoxAdditionalPaddingForCarouselArrows = styled.div`
  position: relative;
  padding: 0 28px;
  width: 100%;
  display: flex;
  justify-content: center;

  ${tabletDownMq} {
    padding: 0;
  }
`

export type HomePageCarouselProduct = ReturnType<typeof useShopPageSections>['sections'][number]['products'][number]

export function HomePage() {
  const { isAuthenticated } = useAccount()

  const { userHasGoals } = useUserHasGoals()

  const hasNonFreeMembership = useUserHasNonFreeMembership()

  const { cookies } = useCookies()

  const showSurveyCompletedUi = userHasGoals || cookies[userHasGoalsCookieName] === 'true'

  const upgradeHomePageDataTilesIsEnabled = useFeatureFlag(featureFlags.upgradeHomePageDataTiles).isEnabled
  return (
    <Container>
      <HomePageBanner showSurveyCompletedUi={showSurveyCompletedUi} />
      {hasNonFreeMembership ? <LazyExistingMemberProductSubBanner /> : <LazyConferencePromotionAnnouncement />}
      {isAuthenticated && upgradeHomePageDataTilesIsEnabled && <LazyDataSourceTilesSection />}
      <Section>
        <ContentBoxAdditionalPaddingForCarouselArrows>
          <ContentBox>
            <SectionHeaderContainer>
              <SectionHeaderText>Supplements</SectionHeaderText>
              <SectionSubheaderText>
                Curated premium supplements shipped directly from the manufacturer.
              </SectionSubheaderText>
            </SectionHeaderContainer>
            <CarouselContainer>
              <React.Suspense fallback={<HomePageCarousel loading />}>
                <HomePageCarousel />
              </React.Suspense>
            </CarouselContainer>
            <LightGreenRoundLinkWithArrow href={shopSupplementsRoute}>See All Supplements</LightGreenRoundLinkWithArrow>
          </ContentBox>
        </ContentBoxAdditionalPaddingForCarouselArrows>
      </Section>
      <Section>
        <BottomSectionContentBox>
          <SectionHeaderContainer>
            <SectionHeaderText>Lab Tests</SectionHeaderText>
            <SectionSubheaderText>
              Directly order expertly tailored lab testing packages without a doctor visit.
            </SectionSubheaderText>
          </SectionHeaderContainer>
          <HomePageFeaturedPackagesSection />
        </BottomSectionContentBox>
      </Section>
    </Container>
  )
}
