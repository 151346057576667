import { createInstance, OptimizelyProvider } from '@optimizely/react-sdk'
import * as React from 'react'
import { rolloutsDataFileApiRoute } from '../../common/constants/routes'
import { getClientSideEnv } from '../../env/getEnvs'

export enum featureFlags {
  lifeEvents = 'life_events',
  userGroups = 'user_groups',
  supplementStackModal = 'supplement_stack_modal',
  genderIdentity = 'gender_identity',
  metricsExplorer = 'metrics_explorer',
  nonMemberLabOrdering = 'non-member_lab_ordering',
  orderHistory = 'order_history',
  labsOnlyMembershipView = 'labs_only_membership_view',
  actionPlans = 'action_plans',
  actionPlansReports = 'action_plans_reports',
  actionPlansCircles = 'action_plans_circles',
  removeAccountQuickLink = 'remove_account_quick_link',
  actionItemUpdates = 'action_item_updates',
  createCircles = 'create_circles',
  circlesFullProfile = 'circles_full_profile',
  circlesChat = 'circles_chat',
  notifications = 'notifications',
  sleepCircleReports = 'sleep_circle_reports',
  achievement_notifications = 'achievement_notifications',
  profilePageTrophies = 'profile_page_trophies',
  circleTimelineComments = 'circle_timeline_comments',
  circleOverviewRelatedContent = 'circle_overview_related_content',
  kpis = 'kpis',
  labResultsV2 = 'lab_results_v2',
  showGuidancePageNotificationOnKpisPage = 'show_guidance_page_notification_on_kpis_page',
  consentRequirementIntake = 'consent_requirement_intake',
  atHomeLabDrawIntake = 'at_home_lab_draw_intake',
  intakeWizardBreadcrumbs = 'intake_wizard_breadcrumbs',
  useVitalContent = 'use_vital_content',
  overrideComingSoon = 'override_coming_soon',
  skipVersionDropdownAndCircleUiOnGuidancePage = 'skip_version_dropdown_and_circle_ui_on_guidance_page',
  labPackageAddOns = 'lab_package_add_ons',
  switchHomePageBannerToNonPromoVersion = 'switch_home_page_banner_to_non_promo_version',
  upgradeHomePageDataTiles = 'upgrade_home_page_data_tiles',
}

const defaultLogLevel = 4
const logLevel = (() => {
  if (typeof window === 'undefined') return defaultLogLevel
  const logLevelParam = new URLSearchParams(location.search).get('optimizelyLogLevel')
  if (logLevelParam) {
    return +logLevelParam
  } else {
    return defaultLogLevel
  }
})()

export const optimizelyClient = createInstance({
  sdkKey: getClientSideEnv().NEXT_PUBLIC_OPTIMIZELY_SDK_KEY,
  // taken directly from docs. use defaults just to silence the console warnings
  eventBatchSize: typeof window !== 'undefined' ? 10 : 100,
  eventFlushInterval: typeof window !== 'undefined' ? 1000 : 30000,
  logLevel,
  datafileOptions: {
    urlTemplate: rolloutsDataFileApiRoute,
  },
})

export function FeatureFlagProvider({ children }: React.PropsWithChildren<{}>) {
  return (
    <OptimizelyProvider timeout={1000} isServerSide={typeof window === 'undefined'} optimizely={optimizelyClient}>
      {children}
    </OptimizelyProvider>
  )
}
