import styled from '@emotion/styled'
import { notebookDownMq } from '../../../styles/mediaQueries'
import { theme } from '../../../styles/theme'
import { surveyScreenUrl } from '../../recommendations/common'
import { cdnUrl } from '../../../common/constants/s3ImageUrls'
import { NextImage } from '../../../common/functionality/NextImage'
import { NavigationArrow } from '../../../assets/components/NavigationArrow'
import { NextLink } from '../../../common/functionality/NextLink'

/* eslint-disable regex/invalid-error, no-restricted-imports */
import ClipboardPng from '../assets/clipboard.png'
import TestTubePng from '../assets/test-tube.png'
import speedometerPng from '../assets/speedometer.png'
import clsx from 'clsx'
import { Display } from '../../../common/style/Display'
import { css } from '@emotion/react'
/* eslint-enable regex/invalid-error, no-restricted-imports */

const Container = styled.div`
  overflow: hidden;
  height: 526px;
  background-color: ${theme.colors.primary900};
  position: relative;
  display: flex;
  flex-direction: column;

  ${notebookDownMq} {
    height: unset;
    padding: 40px 32px 40px;
    min-height: 542px;
  }
`

const Content = styled.div`
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: center;
  flex: 1;

  ${notebookDownMq} {
    justify-content: flex-start;
    flex-direction: column;
  }
`
const HeaderTextContent = styled.div`
  margin-left: 6vw;
  margin-right: 37px;
  display: flex;
  flex-direction: column;
  max-width: 507px;
  width: 50%;
  padding-top: 83px;
  min-width: 507px;

  ${notebookDownMq} {
    margin-left: 0;
    padding-top: 0;
    width: 100%;
    min-width: unset;
    margin-right: 0;
  }
`

const desktopImageUrl = cdnUrl('/home-page/home-page-collage-desktop.webp')
const mobileImageUrl = cdnUrl('/home-page/home-page-collage-mobile.webp')
const bgImageUrl = cdnUrl('general/home-page-hero-background-leaves.png')

const HeroBackground = styled(NextImage)`
  background: ${theme.colors.grayscale900};
  z-index: 0;
  position: relative;
`

type Props = { showSurveyCompletedUi: boolean }

const items = [
  { image: ClipboardPng, text: 'Take our survey to determine your health and wellness goals.', alt: 'clipboard' },
  {
    image: TestTubePng,
    text: 'Get your lab tests to get a baseline read to best determine your course of action.',
    alt: 'test-tube',
  },
  {
    image: speedometerPng,
    text: 'Receive recommendations on how to make progress through tracking, lifestyle adjustments, and supplementation.',
    alt: 'speedometer',
  },
]

const ImageContainer = styled.div`
  position: relative;
  flex: 1;
`

export function HomePageBanner({ showSurveyCompletedUi }: Props) {
  return (
    <Container>
      <HeroBackground src={bgImageUrl} fill alt="hero-leaves-background" />
      <Content>
        <HeaderTextContent>
          <div className="mb-[285px] font-ogg text-5xl font-light leading-[56px] text-grayscale00 lg:mb-[35px]">
            Achieve your goals.
          </div>

          <Display isNotebookDown>
            <div className="absolute h-[500px] w-full translate-y-[-76%]">
              <NextImage src={mobileImageUrl} alt="home-page-collage" fill style={{ objectFit: 'contain' }} />
            </div>
          </Display>

          <div className="flex flex-col gap-[30px] pl-6">
            {items.map((x, i) => (
              <div
                // it is not working putting this in a tailwind ml-[...] class. no idea why but just forcing it with vanilla css
                css={css`
                  margin-left: ${24 * i}px;

                  ${notebookDownMq} {
                    margin-left: 0;
                  }
                `}
                className={clsx(`flex items-center gap-3`)}
                key={x.alt}
              >
                <NextImage src={x.image} alt={x.alt} width={42} height={42} />
                <div className="font-ibmPlexMono text-sm font-light text-grayscale000">{x.text}</div>
              </div>
            ))}
          </div>
          {!showSurveyCompletedUi && (
            <NextLink
              href={surveyScreenUrl}
              css={css`
                margin-left: ${24 * (items.length + 2)}px;
                ${notebookDownMq} {
                  margin-left: 0;
                  align-self: center;
                }
              `}
              className={clsx(`mt-[42px]`)}
            >
              <button className="flex h-[52px] w-[218px] items-center justify-center gap-0.5 rounded-full bg-primary700 px-5 py-4 transition hover:bg-primary800">
                <div className="text-base font-medium tracking-[0.16px] text-grayscalewhite">Take Survey</div>
                <NavigationArrow orientation="right" color={theme.colors.grayscalewhite} size={18} />
              </button>
            </NextLink>
          )}
        </HeaderTextContent>
        <ImageContainer
          css={css`
            ${notebookDownMq} {
              display: none;
            }
          `}
        >
          <NextImage src={desktopImageUrl} alt="home-page-collage" fill style={{ objectFit: 'contain' }} />
        </ImageContainer>
      </Content>
    </Container>
  )
}
