import Image, { ImageLoaderProps, ImageProps } from 'next/image'
import * as React from 'react'
import { isBuilt } from '../../utilities/isBuilt'
import { cdnUrl } from '../constants/s3ImageUrls'
import { imageDomains } from '../constants/imageDomains.mjs'

export const axoGlobalFallbackImageSrc = cdnUrl('general/axo-global-fallback-image.png')

const wildcardLoader = ({ src }: ImageLoaderProps) => {
  if (!isBuilt) console.error('!! Unknown image domain for next/image. Image is not optimized. src: ', src)
  return src
}

type Props = ImageProps & { isLoading?: boolean }

export function NextImage({ isLoading, ...props }: Props) {
  const [hasError, setHasError] = React.useState(false)
  React.useEffect(() => {
    setHasError(false)
  }, [props.src])

  const src = hasError ? axoGlobalFallbackImageSrc : props.src

  // if the src coming through does not match any of our domains, we don't want next to do anything to it, so we use a
  // loader to just have it allow the src to go through to the img tag as it is when sent in
  const loaderProps = React.useMemo(() => {
    if (!src || typeof src !== 'string') return
    if (!imageDomains.some((x) => typeof src === 'string' && src.includes(x))) {
      return { loader: wildcardLoader }
    }
  }, [src])

  const shouldRender = src || !isLoading
  return (
    <>
      {shouldRender && (
        <Image
          {...loaderProps}
          {...props}
          // placing this separately to solve for a11y linting error
          alt={props.alt}
          // optimization should be done at the CDN level, not through next optimizing the image on our origin server
          unoptimized
          src={src}
          onError={(...args) => {
            if (typeof props.onError === 'function') props.onError(...args)
            setHasError(true)
          }}
        />
      )}
    </>
  )
}
